import { Typography, CardContent, Card } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { IRecord, FontSizes, TextColors } from "common";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import Repo from "../Repo";
import { TokenService } from "../TokenService";

interface IProps extends RouteComponentProps<{ id: string, recordId:string }> {}

const RecordScreen: React.FunctionComponent<IProps> = ({
    match: {
      params: { id, recordId }
    }
  }) => {

    
    const [record, setRecord] = useState<IRecord>({
        detail:"",         
id:"",

    dateOccurred: {
        when:"",
        date:new Date()
    },
    categoryKey: "",
    categoryTags: [],
    carer: {
        id:"", firstname:"", lastname:"", avatar:"",
    },
    resident:  {
        id:"", firstname:"", lastname:"", avatar:"",
    },
    isGood: false
    });
    const repo = new Repo(new TokenService());

    useEffect(()=>{
        repo.GetResidentRecords(id).then(record => {
            setRecord(record.filter(x=>x.id===recordId)[0])
        });
    }, [id]);

    return (<Grid>
        <Card
        key={"record_" + record.id}
        style={{ marginRight: 6, marginBottom: 12, width: 340 }}
      >
        <CardContent>
          <Typography style={{ fontSize: FontSizes.Title }} gutterBottom={true}>
            {record.categoryKey}
          </Typography>
          <Typography style={{ fontSize: FontSizes.Small }}>
            {record.categoryTags.map(tag => {
              return tag + ", ";
            })}
          </Typography>

          <Typography
            style={{
              fontSize: FontSizes.Copy,
              marginTop: 12,
              marginBottom: 12
            }}
          >
            {record.detail}
          </Typography>
          <Typography
            style={{
              color: TextColors.Secondary,
              fontSize: FontSizes.Small
            }}
          >
            {record.dateOccurred.when +
              " by " +
              record.carer.firstname +
              " " +
              record.carer.lastname}
          </Typography>
        </CardContent>
      </Card>
    </Grid>)
  }
export default RecordScreen;