import React from "react";
import { ICarer } from "common";
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  makeStyles,
  createStyles,
  Theme,
  Typography
} from "@material-ui/core";
import { Link } from "react-router-dom";
import LockIcon from "@material-ui/icons/Lock";
import AddCarer from "../modals/AddCarer";

interface IProps {
  carers: ICarer[];
  onSelected(): any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      left: "0"
    }
  })
);

const CarerListItems: React.SFC<IProps> = props => {
  const classes = useStyles();

  return (
    <div>
      <ListItem>
        <ListItemText primary="Carers" />

        <ListItemSecondaryAction>
          <AddCarer />
        </ListItemSecondaryAction>
      </ListItem>

      {props.carers.map(carer => {
        return (
          <ListItem
            button={true}
            key={`shift_${carer.id}`}
            component={Link}
            to={`/carer/${carer.id}`}
            onClick={() => {
              props.onSelected();
            }}
          >
            {carer.active && <LockIcon color="error" />}
            <Typography>
              {carer.firstname} {carer.lastname}
            </Typography>
          </ListItem>
        );
      })}
    </div>
  );
};

export default CarerListItems;
