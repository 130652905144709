import React, { useState, useEffect } from "react";
import {
  IconButton,
  Grid,
  Drawer,
  Select,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  ButtonGroup,
  Typography,
  DialogContent,
  DialogActions,
  Dialog,
  DialogTitle,
  MenuItem,
  InputLabel,
  FormControl
} from "@material-ui/core";
import AddCircle from "@material-ui/icons/AddCircle";
import { IConfigCategory, IWorker } from "common";
import Repo from "../Repo";
import { TokenService } from "../TokenService";
import { RouteComponentProps } from "react-router";

interface IProps extends RouteComponentProps<{ id: string }> {}

const AddCarePlan: React.FunctionComponent<IProps> = props => {
  const repo = new Repo(new TokenService());
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [tmpAction, setTmpAction] = useState<string>("");
  const [actions, setActions] = useState<string[]>([]);
  const [level, setLevel] = useState<number>(3);
  const [goal, setGoal] = useState<string>("");
  const [activity, setActivity] = useState<string>("");
  const [categoryKey, setCategoryKey] = useState<string>("");
  const [categories, setCategories] = useState<IConfigCategory[]>([]);
  const [worker, setWorker] = useState<IWorker>({
    error: "",
    isWorking: false
  });

  const doAddCareplan = () => {
    setWorker({ error: "", isWorking: true });
    repo
      .AddCareplanAsync(
        props.match.params.id,
        level,
        activity,
        goal,
        categoryKey,
        actions
      )
      .then(res => {
        setWorker({ error: "", isWorking: false });
        setIsModalOpen(false);
      })
      .catch(ex => {
        setWorker({ error: ex.message, isWorking: false });
        setIsModalOpen(false);
      });
  };

  useEffect(() => {
    repo.GetConfigurationAsync().then(config => {
      setCategories(config.configuration.configuration.categories);
      setCategoryKey(config.configuration.configuration.categories[0].key);
    });
  }, []);

  const handleChange = (event: any) => {
    setLevel(parseInt(event.target.value, 10));
  };

  return (
    <Grid>
      <IconButton onClick={() => setIsModalOpen(true)}>
        <AddCircle />
      </IconButton>

      <Dialog
        open={isModalOpen}
        fullScreen={true}
        style={{ margin: 24 }}
        onClose={() => setIsModalOpen(false)}
      >
        <DialogTitle>Add Careplan</DialogTitle>
        <DialogContent>
          <Grid container={true}>
            <Grid item={true} xs={6}>
              <FormControl style={{ margin: 6, minWidth: 120 }}>
                <InputLabel shrink={true}>Category</InputLabel>
                <Select
                  value={categoryKey}
                  onChange={(e: any) => {
                    setCategoryKey(e.target.value);
                  }}
                >
                  {categories.map(cat => (
                    <MenuItem key={"option_" + cat.key} value={cat.key}>
                      {cat.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl style={{ margin: 6, minWidth: 120 }}>
                <InputLabel shrink={true}>Dependency Level</InputLabel>
                <Select
                  value={level}
                  onChange={(e: any) => {
                    setLevel(e.target.value);
                  }}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Activity"
                multiline={true}
                rows="10"
                fullWidth={true}
                value={activity}
                onChange={e => setActivity(e.target.value)}
              />
              <TextField
                label="Goal"
                multiline={true}
                rows="7"
                fullWidth={true}
                value={goal}
                onChange={e => setGoal(e.target.value)}
              />
            </Grid>

            <Grid item={true} xs={6}>
              <Typography>Actions</Typography>
              <Grid item={true}>
                <TextField
                  value={tmpAction}
                  onChange={e => {
                    setTmpAction(e.target.value);
                  }}
                />
                <Button
                  onClick={() => {
                    setActions([...actions, tmpAction]);
                    setTmpAction("");
                  }}
                >
                  Add another action
                </Button>
                {actions.map((action, i) => {
                  return (
                    <Typography style={{ margin: 6 }}>
                      {i + 1 + ": " + action}
                    </Typography>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => doAddCareplan()}>Add Careplan</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default AddCarePlan;
