import {
  AppBar,
  Avatar,
  Button,
  ButtonGroup,
  CircularProgress,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Switch,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  Card,
  CardContent
} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SmartphoneIcon from "@material-ui/icons/Smartphone";
import { ICarerEx, IRecord, FontSizes, TextColors, IWorker } from "common";
import React, { useEffect, useState } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import DetailBlob from "../DetailBlob";
import CarerNewCode from "../modals/CarerNewCode";
import DeleteCarer from "../modals/DeleteCarer";
import Repo from "../Repo";
import { TokenService } from "../TokenService";

interface IProps extends RouteComponentProps<{ id: string }> {}

const useStyles = makeStyles(() =>
  createStyles({
    name: {
      flexGrow: 1,
      marginLeft: 16
    }
  })
);

export const CarerScreen: React.FunctionComponent<IProps> = ({
  match: {
    params: { id }
  }
}) => {
  const classes = useStyles();
  const repo = new Repo(new TokenService());

  const [records, setRecords] = useState<IRecord[]>([]);

  const [carer, setCarer] = useState<ICarerEx>({
    id: "",
    firstname: "",
    lastname: "",
    xx: false,
    active: false,
    mobile: "",
    dob: new Date(),
    crb: "",
    avatar: ""
  });

  const [carerUpdate, setCarerUpdate] = useState<ICarerEx>({
    id: "",
    firstname: "",
    lastname: "",
    xx: false,
    active: false,
    mobile: "",
    crb: "",
    dob: new Date(),
    avatar: ""
  });

  const [worker, setWorker] = useState<IWorker>({
    error: "",
    isWorking: false
  });
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [isNewCodeOpen, setIsNewCodeOpen] = useState<boolean>(false);

  useEffect(() => {
    setWorker({ error: "", isWorking: true });
    setIsEditing(false);

    repo
      .CarerGet(id)
      .then(data => {
        setCarer(data);
        setCarerUpdate(data);

        repo
          .AllRecords()
          .then(results => {
            setRecords(results.filter(x => x.carer.id === data.id));
          })
          .catch(ex => {
            throw ex;
          });

        setWorker({ error: "", isWorking: false });
      })
      .catch(() => {
        setWorker({ error: "", isWorking: false });
      });
  }, [id]);

  const handleCancel = () => {
    setCarerUpdate({ ...carer });
    setIsEditing(false);
  };

  const handleSwitchActive = () => {
    setWorker({ error: "", isWorking: true });

    var obj: { [k: string]: any } = {};
    obj.active = !carerUpdate.active;

    doUpdate(obj);
  };

  const handleSave = () => {
    setWorker({ error: "", isWorking: true });
    var obj: { [k: string]: any } = {};

    if (carerUpdate.firstname !== carer.firstname) {
      obj.firstname = carerUpdate.firstname;
    }

    if (carerUpdate.lastname !== carer.lastname) {
      obj.lastname = carerUpdate.lastname;
    }

    if (carerUpdate.mobile !== carer.mobile) {
      obj.mobile = carerUpdate.mobile;
    }

    if (carerUpdate.crb !== carer.crb) {
      obj.crb = carerUpdate.crb;
    }

    if (carerUpdate.dob !== carer.dob) {
      obj.dob = carerUpdate.dob;
    }

    if (carerUpdate.active !== carer.active) {
      obj.active = carerUpdate.active;
    }

    doUpdate(obj);
  };

  const doUpdate = (obj: { [k: string]: any }) => {
    if (obj != {}) {
      repo
        .CarerUpdate(id, obj)
        .then((r: ICarerEx) => {
          setCarer(r);
          setCarerUpdate(r);
          setIsEditing(false);
          setWorker({ error: "", isWorking: false });
        })
        .catch(() => {
          setIsEditing(false);
          setWorker({ error: "", isWorking: false });
        });
    }
  };

  const RecordCard = (res: IRecord) => {
    return (
      <Card
        key={"record_" + res.id}
        style={{ marginRight: 6, marginBottom: 12, width: 340 }}
      >
        <CardContent>
          <Typography style={{ fontSize: FontSizes.Title }} gutterBottom={true}>
            {res.categoryKey +
              ": " +
              res.resident.firstname +
              " " +
              res.resident.lastname}
          </Typography>
          <Typography style={{ fontSize: FontSizes.Small }}>
            {res.categoryTags.map(tag => {
              return tag + ", ";
            })}
          </Typography>

          <Typography
            style={{
              fontSize: FontSizes.Copy,
              marginTop: 12,
              marginBottom: 12
            }}
          >
            {res.detail}
          </Typography>
          <Typography
            style={{
              color: TextColors.Secondary,
              fontSize: FontSizes.Small
            }}
          >
            {res.dateOccurred.when +
              " by " +
              res.carer.firstname +
              " " +
              res.carer.lastname}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  const DetailView: React.FunctionComponent = () => {
    return (
      <div>
        <AppBar position="relative" color="default">
          <Toolbar>
            <Avatar alt={carer.firstname} src={carer.avatar} />
            <Typography variant="h6" className={classes.name}>
              {carer.firstname} {carer.lastname}
            </Typography>
            <Switch
              checked={carerUpdate.active}
              onChange={e => handleSwitchActive()}
            />
            <IconButton color="inherit" onClick={() => setIsNewCodeOpen(true)}>
              <Tooltip title="Get a new login code">
                <SmartphoneIcon />
              </Tooltip>
            </IconButton>
            <IconButton color="inherit" onClick={e => setIsEditing(true)}>
              <Tooltip title="Edit">
                <EditIcon />
              </Tooltip>
            </IconButton>
            <IconButton onClick={e => setIsDeleteOpen(true)} color="inherit">
              <Tooltip title="Delete carer">
                <DeleteIcon />
              </Tooltip>
            </IconButton>
          </Toolbar>
        </AppBar>

        <Grid container={true} direction="column" style={{ margin: 24 }}>
          <Grid item={true}>
            <DetailBlob title="CRB" sub={carer.crb} />
          </Grid>
          <Grid item={true}>
            <DetailBlob title="Mobile" sub={carer.mobile} />
          </Grid>
          <Grid item={true}>
            <DetailBlob
              title="Date of Birth"
              sub={new Date(carer.dob).toLocaleDateString("en-GB")}
            />
          </Grid>
        </Grid>

        <Grid container={true}>
          {records.map(record => {
            return (
              <Grid item={true}>
                <RecordCard key={"record_" + record.id} {...record} />
              </Grid>
            );
          })}
        </Grid>

        <CarerNewCode
          id={id}
          isOpen={isNewCodeOpen}
          onClose={() => setIsNewCodeOpen(false)}
        />

        <DeleteCarer
          id={carer.id}
          onClose={(reload: boolean) => {
            setIsDeleteOpen(false);
            if (reload) {
              return <Redirect to="/" />;
            }
          }}
          isOpen={isDeleteOpen}
        />
      </div>
    );
  };

  return (
    <div>
      {worker.isWorking ? (
        <CircularProgress />
      ) : !isEditing ? (
        <DetailView />
      ) : (
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <TextField
              label="Firstname"
              value={carerUpdate.firstname}
              onChange={e =>
                setCarerUpdate({ ...carerUpdate, firstname: e.target.value })
              }
            />
          </Grid>
          <Grid item>
            <TextField
              label="Lastname"
              value={carerUpdate.lastname}
              onChange={e =>
                setCarerUpdate({ ...carerUpdate, lastname: e.target.value })
              }
            />
          </Grid>
          <Grid item>
            <TextField
              label="Mobile"
              value={carerUpdate.mobile}
              onChange={e =>
                setCarerUpdate({ ...carerUpdate, mobile: e.target.value })
              }
            />
          </Grid>
          <Grid item>
            <TextField
              label="CRB"
              value={carerUpdate.crb}
              onChange={e =>
                setCarerUpdate({ ...carerUpdate, crb: e.target.value })
              }
            />
          </Grid>
          <Grid item>
            <TextField
              label="Birthday"
              type="date"
              defaultValue={carerUpdate.dob}
              value={carerUpdate.dob}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item>
            <Switch
              checked={carerUpdate.active}
              onChange={e =>
                setCarerUpdate({ ...carerUpdate, active: e.target.checked })
              }
              value="Active"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </Grid>
          <Grid item>
            <ButtonGroup>
              <Button onClick={e => handleCancel()}>Cancel</Button>
              <Button onClick={e => handleSave()}>Save</Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      )}
    </div>
  );
};
