import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { IConfig } from "common";
import React, { useEffect, useState, Context, Component } from "react";
import "typeface-roboto";
import Dashboard from "./components/Dashboard";
import NavList from "./components/Lists/NavList";
import Navbar from "./components/NavBar";
import Repo from "./components/Repo";
import CarePlanScreen from "./components/screens/CarePlansScreen";
import { CarerScreen } from "./components/screens/CarerScreen";
import DeviceScreen from "./components/screens/DeviceScreen";
import { ResidentScreen } from "./components/screens/ResidentScreen";
import Shift from "./components/Shift";
import Splash from "./components/Splash";
import { TokenService } from "./components/TokenService";
import PrivateRoute from "./PrivateRoute";
import RecordScreen from "./components/screens/RecordScreen";

export interface IAdminConfiguration {
  me: {
    firstname: string;
    lastname: string;
  };
  configuration: IConfig;
}

const drawerWidth = 320;

export const AppContext = React.createContext<IConfig>({
  name:"", 
  key:"", 
  configuration: {
    categories:[]
  }
});

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      flexGrow: 1
    },
    root1: {
      display: "flex",
      flexGrow: 1,
      flexDirection: "column"
    },
    nav: {
      borderRight: 1,
      width: drawerWidth,
      borderRightColor: "#cccccc",
      borderRightStyle: "solid",
      borderRightWidth: 1,
      backgroundColor: "#eeeeee",
      overflow: "hidden"
    },
    main: {
      flexGrow: 1,
      padding: 20
    }
  })
);

export class TestService {
  public static Thing: IConfig;
}

export const App: React.FunctionComponent = () => {
  const classes = useStyles();

  const tokenService = new TokenService();
  const repo = new Repo(tokenService);

  const [config, setConfig] = useState<IAdminConfiguration>({
    me: {
      firstname: "",
      lastname: ""
    },
    configuration: {
      name: "",
      key: "",
      configuration: {
        categories: []
      }
    }
  });

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  const fetchConfiguration = () => {
    repo
      .GetConfigurationAsync()
      .then(res => {
        setConfig(res);
        TestService.Thing = res.configuration;
        document.title = `${res.configuration.name} - Jrnal`;
      })
      .catch(() => {});
  };

  const checkToken = async () => {
    const token = await tokenService.getAsync();

    if (token.bearer) {
      setLoggedIn();
    }
  };

  useEffect(() => {
    checkToken().catch(() => {});
  }, []);

  const setLoggedIn = () => {
    fetchConfiguration();
    setIsLoggedIn(true);
  };

  const setLoggedOut = () => {
    tokenService.remove();
    setConfig({
      me: {
        firstname: "",
        lastname: ""
      },
      configuration: {
        name: "",
        key: "",
        configuration: {
          categories: []
        }
      }
    });
    document.title = "Jrnal";
    setIsLoggedIn(false);
  };

  const switchDrawerOpen = (open: boolean) => {
    setIsDrawerOpen(open);
  };

  return !isLoggedIn ? (
    <Splash onLoggedIn={setLoggedIn} />
  ) : (
   <AppContext.Provider value={{
     name: config.configuration.name,
     key: config.configuration.key,     
     configuration: {
       categories:  config.configuration.configuration.categories
     }
   }}>
    <div className={classes.root1}>
      <Navbar
        firstname={config.me.firstname}
        lastname={config.me.lastname}
        carehome={config.configuration.name}
        switchDrawerOpen={switchDrawerOpen}
        onLoggedOut={setLoggedOut}
      />

      <div className={classes.root}>
        <NavList
          isLoggedIn={isLoggedIn}
          isOpen={isDrawerOpen}
          carehome={config.configuration.key}
          onClose={() => {
            setIsDrawerOpen(false);
          }}
        />

        <main className={classes.main}>
          <PrivateRoute
            path="/"
            exact={true}
            component={Dashboard}
            isLoggedIn={isLoggedIn}
          />
          <PrivateRoute
            path="/shift/:id"
            component={Shift}
            isLoggedIn={isLoggedIn}
          />
          <PrivateRoute
            path="/carer/:id"
            component={CarerScreen}
            isLoggedIn={isLoggedIn}
          />
          <PrivateRoute
            path="/su/:id"
            component={ResidentScreen}
            isLoggedIn={isLoggedIn}
          />
          <PrivateRoute
            path="/resident/:id/careplans"
            component={CarePlanScreen}
            isLoggedIn={isLoggedIn}
          />
          <PrivateRoute
            path="/device/:hardwareId"
            component={DeviceScreen}
            isLoggedIn={isLoggedIn}
          />
          <PrivateRoute path="/resident/:id/record/:recordId" 
            component={RecordScreen} 
            isLoggedIn={isLoggedIn} />
        </main>
      </div>
    </div>
    </AppContext.Provider>
  );
};
