import { Typography } from "@material-ui/core";
import React from "react";

const DetailBlob: React.FunctionComponent<{
  title: string;
  sub: string;
}> = props => {
  return (
    <div>
      <Typography variant="h5" align="left">
        {props.title}:
      </Typography>
      <Typography variant="h6" align="left">
        {props.sub}
      </Typography>
      {props.sub === "" && <Typography variant="h6">...</Typography>}
    </div>
  );
};

export default DetailBlob;
