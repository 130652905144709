import React, { useState } from "react";
import Repo from "../Repo";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions
} from "@material-ui/core";
import { TokenService } from "../TokenService";

interface IProps {
  id: string;
  isOpen: boolean;
  onClose(): any;
}

const CarerNewCode: React.FunctionComponent<IProps> = props => {
  const repo = new Repo(new TokenService());

  const [code, setCode] = useState<string>("");

  const fetch = async () => {
    repo.CarerNewCode(props.id).then((code: string) => {
      setCode(code);
    });
  };

  return (
    <Dialog open={props.isOpen}>
      <DialogTitle>Create new code</DialogTitle>
      <DialogContent>
        <TextField value={code} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            fetch();
          }}
        >
          Create
        </Button>
        <Button
          onClick={() => {
            props.onClose();
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CarerNewCode;
