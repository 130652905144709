import {
  ListItem,
  ListItemSecondaryAction,
  ListItemText
} from "@material-ui/core";
import { IDevice } from "common";
import React from "react";
import { Link } from "react-router-dom";
import AddDevice from "../modals/AddDevice";

interface IProps {
  devices: IDevice[];
  onSelected(): any;
}

const DeviceListItems: React.FunctionComponent<IProps> = props => {
  return (
    <div>
      <ListItem>
        <ListItemText primary="Devices" />
        <ListItemSecondaryAction>
          <AddDevice />
        </ListItemSecondaryAction>
      </ListItem>

      {props.devices.map(device => {
        return (
          <ListItem
            key={`device_${device.id}`}
            component={Link}
            to={`/device/${device.hardwareId}`}
            onClick={() => props.onSelected()}
          >
            <ListItemText
              primary={device.hardwareId}
              secondary={
                device.last_login.firstname + " " + device.last_login.lastname
              }
            />
          </ListItem>
        );
      })}
    </div>
  );
};

export default DeviceListItems;
