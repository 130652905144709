import { Broker, ICarerEx, ICareplan, IRecord, ITokenStorage } from "common";
import { IAdminConfiguration } from "../App";

export default class Repo {
  private _api: Broker;
  private _tokenService: ITokenStorage;

  constructor(tokenService: ITokenStorage) {
    this._tokenService = tokenService;
    this._api = new Broker("admin", this._tokenService);
  }

  // Devices

  public GetDevices = async () => {
    return this._api.Fetch("devices", {
      method: "GET"
    });
  };

  public DeviceAdd = async (code: string) => {
    const json = await this._api.Fetch(`devices/${code}`, {
      method: "POST"
    });

    return json.password;
  };

  public DeviceDelete = async (hardwareId: string) => {
    await this._api.Fetch(`devices/${hardwareId}`, {
      method: "DELETE"
    });
  };

  public GetDevice = async (hardwareId: string) => {
    return this._api.Fetch(`device/${hardwareId}`, {
      method: "GET"
    });
  };

  public CarerNewCode = async (id: string) => {
    const json = await this._api.Fetch(`carer/${id}/code`, {
      method: "PUT"
    });

    return json.password;
  };

  public CarerAdd = async (inFirstname: string, inLastname: string) => {
    await this._api.Fetch("carers", {
      method: "POST",
      body: JSON.stringify({ firstname: inFirstname, lastname: inLastname })
    });

    return true;
  };

  public CarerDelete = async (id: string) => {
    await this._api.Fetch(`carer/${id}`, {
      method: "DELETE"
    });

    return true;
  };

  public CarerUpdate = async (id: string, body: any) => {
    return this._api.Fetch(`carer/${id}`, {
      method: "PUT",
      body: JSON.stringify(body)
    });
  };

  public CarerGet = async (id: string): Promise<ICarerEx> =>
    this._api.Fetch(`carer/${id}`, {
      method: "GET"
    });

  public Carers = async () => {
    return this._api.Fetch("carers", {
      method: "GET"
    });
  };

  public ServiceUserAdd = async (
    inFirstname: string,
    inLastname: string,
    inNickname: string
  ) => {
    await this._api.Fetch("residents", {
      method: "POST",
      body: JSON.stringify({
        firstname: inFirstname,
        lastname: inLastname,
        nickname: inNickname
      })
    });

    return true;
  };

  public ServiceUserUpdate = async (id: string, body: any) => {
    return this._api.Fetch(`resident/${id}`, {
      method: "PUT",
      body: JSON.stringify(body)
    });
  };

  public ServiceUserDelete = async (id: string) => {
    await this._api.Fetch(`resident/${id}`, {
      method: "DELETE"
    });

    return true;
  };

  public ServiceUserGet = async (id: string) => {
    return this._api.Fetch(`resident/${id}`, {
      method: "GET"
    });
  };

  public ServiceUsers = async () => {
    return this._api.Fetch("residents", {
      method: "GET"
    });
  };

  public Shift = async (id: string) => {
    return this._api.Fetch(`shift/${id}`, {
      method: "GET"
    });
  };

  public GetConfigurationAsync = async (): Promise<IAdminConfiguration> =>
    this._api.Fetch("configuration", {
      method: "GET"
    });

  public Login = async (inUsername: string, inPassword: string) => {
    const json = await this._api.Fetch(`login`, {
      method: "POST",
      body: JSON.stringify({ username: inUsername, password: inPassword })
    });

    if (json.token !== "") {
      this._tokenService.set(json.token);
    }
  };

  public AllRecords = async (): Promise<IRecord[]> => {
    return this._api.Fetch("records", {
      methods: "GET"
    });
  };

  public GetResidentRecords = async (
    id: string,
  ): Promise<IRecord[]> => {
    return this._api.Fetch(`resident/${id}/records`, {
      method: "GET"
    });
  };

  public GetCareplansAsync = async (id: string): Promise<ICareplan[]> =>
    this._api.Fetch(`resident/${id}/careplan`, {
      method: "GET"
    });

  public AddCareplanAsync = async (
    id: string,
    inLevel: number,
    inCopy: string,
    inGoal: string,
    inCategoryKey: string,
    inActions: string[]
  ): Promise<ICareplan> =>
    this._api.Fetch(`resident/${id}/careplan`, {
      method: "POST",
      body: JSON.stringify({
        level: inLevel,
        copy: inCopy,
        goal: inGoal,
        actions: inActions,
        category: {
          key: inCategoryKey
        }
      })
    });
}
