import React from "react";
import { Redirect, RouteProps } from "react-router";
import { Route } from "react-router-dom";

export interface IPrivateRouteProps extends RouteProps {
  component: any;
  isLoggedIn: boolean;
}

const PrivateRoute = (props: IPrivateRouteProps) => {
  return (
    <Route
      {...props}
      render={routeProps =>
        props.isLoggedIn ? (
          <props.component {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: routeProps.location }
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
