import {
  AppBar,
  IconButton,
  Toolbar,
  Tooltip,
  Typography
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { IDevice } from "common";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import DeleteDevice from "../modals/DeleteDevice";
import Repo from "../Repo";
import { TokenService } from "../TokenService";

interface IProps extends RouteComponentProps<{ hardwareId: string }> {}

const DeviceScreen: React.FunctionComponent<IProps> = ({
  match: {
    params: { hardwareId }
  }
}) => {
  const repo = new Repo(new TokenService());

  const [device, setDevice] = useState<IDevice>({
    id: "",
    hardwareId: "",
    last_login: {
      id: "",
      firstname: "",
      lastname: "",
      date: new Date()
    }
  });

  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);

  useEffect(() => {
    repo.GetDevice(hardwareId).then(device => {
      setDevice(device);
    });
  }, [hardwareId]);

  return (
    <div>
      <AppBar position="relative" color="default">
        <Toolbar>
          <Typography variant="h6">
            {device.id} = {device.hardwareId}
          </Typography>
          <IconButton onClick={e => setIsDeleteOpen(true)} color="inherit">
            <Tooltip title="Delete device">
              <DeleteIcon />
            </Tooltip>
          </IconButton>
        </Toolbar>
      </AppBar>

      <DeleteDevice
        id={hardwareId}
        isOpen={isDeleteOpen}
        onClose={() => {
          setIsDeleteOpen(false);
        }}
      />
    </div>
  );
};

export default DeviceScreen;
