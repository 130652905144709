import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { IWorker, ICareplan } from "common";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import Repo from "../Repo";
import { TokenService } from "../TokenService";
import LoadingErrorWidget from "../Widgets/LoadingErrorWidget";
import AddCarePlan from "../modals/AddCarePlan";

interface IProps extends RouteComponentProps<{ id: string }> {}

const CarePlansScreen: React.FunctionComponent<IProps> = props => {
  const repo = new Repo(new TokenService());
  const [worker, setWorker] = useState<IWorker>({
    error: "",
    isWorking: false
  });
  const [careplans, setCareplans] = useState<ICareplan[]>([]);

  useEffect(() => {
    setWorker({ error: "", isWorking: true });
    repo
      .GetCareplansAsync(props.match.params.id)
      .then(results => {
        setCareplans(results);
        setWorker({ error: "", isWorking: false });
      })
      .catch(ex => {
        setWorker({ error: ex.message, isWorking: false });
      });
  }, []);

  return (
    <Grid>
      <LoadingErrorWidget {...worker} />

      <AddCarePlan {...props} />

      <Grid item={true}>
        {careplans.map(careplan => (
          <Grid key={"cp_" + careplan.id}>
            <Typography>
              {careplan.category.label + ": Level " + careplan.level}
            </Typography>
            <Typography>Activity</Typography>
            <Typography>{careplan.copy}</Typography>
            <Typography>Goal</Typography>
            <Typography>{careplan.goal}</Typography>
            <Typography>Actions</Typography>
            {careplan.actions.map(action => {
              return <Typography>{"- " + action}</Typography>;
            })}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default CarePlansScreen;
