import React, { useState } from "react";
import Repo from "../Repo";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  IconButton
} from "@material-ui/core";
import AddCircle from "@material-ui/icons/AddCircle";
import { TokenService } from "../TokenService";

const AddDevice: React.FunctionComponent = () => {
  const repo = new Repo(new TokenService());

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [code, setCode] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");

  const register = async () => {
    setError("");
    repo
      .DeviceAdd(code)
      .then((password: string) => {
        setPassword(password);
      })
      .catch(error => {
        setError(error.message);
      });
  };

  return (
    <div>
      <IconButton onClick={() => setIsModalOpen(true)}>
        <AddCircle />
      </IconButton>

      <Dialog open={isModalOpen}>
        <DialogTitle>Confirm Device Code</DialogTitle>

        <DialogContent>
          {error}
          <TextField
            label="Code"
            value={code}
            margin="normal"
            onChange={e => setCode(e.target.value)}
          />

          <TextField
            value={password}
            label="New passcode"
            margin="normal"
            onChange={e => setPassword(e.target.value)}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={() => register()}>Do it!</Button>

          <Button onClick={() => setIsModalOpen(false)} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddDevice;
