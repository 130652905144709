import React from "react";
import AppBar from "@material-ui/core/AppBar";
import ToolBar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import HomeIcon from "@material-ui/icons/Home";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";
import { makeStyles, createStyles } from "@material-ui/core";

interface IProps {
  firstname: string;
  lastname: string;
  carehome: string;
  switchDrawerOpen(open: boolean): any;

  onLoggedOut(): any;
}

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      flexGrow: 1
    }
  })
);

const Navbar: React.FunctionComponent<IProps> = props => {
  const classes = useStyles();

  return (
    <AppBar position="relative">
      <ToolBar>
        <IconButton
          onClick={() => {
            props.switchDrawerOpen(true);
          }}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <IconButton
          component={Link}
          to="/"
          edge="start"
          color="inherit"
          aria-label="Menu"
        >
          <HomeIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          {props.firstname} {props.lastname}
        </Typography>
        <Button color="inherit" onClick={props.onLoggedOut}>
          Logout
        </Button>
      </ToolBar>
    </AppBar>
  );
};

export default Navbar;
