import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Grid,
  Typography
} from "@material-ui/core";
import Repo from "../Repo";
import { TokenService } from "../TokenService";
import { IWorker } from "common";

interface IProps {
  id: string;
  isOpen: boolean;
  onClose(reload: boolean): any;
}

const DeleteCarer: React.FunctionComponent<IProps> = props => {
  const repo = new Repo(new TokenService());

  const [worker, setWorker] = useState<IWorker>({
    error: "",
    isWorking: false
  });

  const handleDelete = () => {
    setWorker({ error: "", isWorking: true });

    repo
      .CarerDelete(props.id)
      .then(() => {
        setWorker({ error: "", isWorking: false });
        props.onClose(true);
      })
      .catch(() => {
        setWorker({ error: "", isWorking: false });
        props.onClose(true);
      });
  };

  return (
    <Dialog open={props.isOpen}>
      <DialogTitle>Delete carer</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item>
            <TextField label="Name" />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {worker.isWorking && (
          <Typography variant="subtitle2">Deleting...</Typography>
        )}
        <Button
          onClick={() => {
            handleDelete();
          }}
        >
          Delete
        </Button>
        <Button
          onClick={() => {
            props.onClose(false);
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCarer;
