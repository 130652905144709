import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Typography,
  CardContent,
  Card,
  CardActionArea,
  CardActions
} from "@material-ui/core";
import { IWorker, LogoColors, FontSizes } from "common";
import React, { useState, useEffect } from "react";
import Logo from "../logo.png";
import Repo from "./Repo";
import { TokenService } from "./TokenService";
import { typeofTypeAnnotation } from "@babel/types";

const useStyles = makeStyles(() =>
  createStyles({
    error: {
      color: "red"
    }
  })
);

interface IProps {
  onLoggedIn(): any;
}

const Splash: React.FunctionComponent<IProps> = props => {
  const classes = useStyles();
  const repo = new Repo(new TokenService());

  const [username, setUsername] = useState<string>("adampengelly");
  const [password, setPassword] = useState<string>("");
  const [worker, setWorker] = useState<IWorker>({
    error: "",
    isWorking: false
  });

  const onLoginClick = () => {
    setWorker({ error: "", isWorking: true });

    const tokenService = new TokenService();

    repo
      .Login(username, password)
      .then(async () => {
        const token = await tokenService.getAsync();
        if (token.bearer) {
          setWorker({ error: "", isWorking: false });
          props.onLoggedIn();
        } else {
          throw Error("No token");
        }
      })
      .catch(ex => {
        setWorker({ error: ex.message, isWorking: false });
      });
  };

  const onUsernameChange = (args: any) => {
    setUsername(args.target.value);
  };

  const onPasswordChange = (args: any) => {
    setPassword(args.target.value);
  };

  return (
    <Grid
      container={true}
      direction="column"
      alignContent="stretch"
      alignItems="center"
      style={{
        flexGrow: 1
      }}
    >
      <Grid
        item={true}
        style={{
          backgroundColor: LogoColors.Red,
          alignSelf: "stretch",
          height: 96
        }}
      >
        <img src={Logo} style={{ width: 120, height: 56 }} alt="Jrnal" />
      </Grid>

      {worker.error && (
        <Grid item={true}>
          <Typography variant="body1" className={classes.error}>
            {worker.error}
          </Typography>
        </Grid>
      )}

      <Card style={{ margin: 24 }}>
        <CardContent>
          <Typography style={{ fontSize: FontSizes.Title, marginBottom: 12 }}>
            Login
          </Typography>
          <Grid item={true}>
            <TextField
              label="Username"
              value={username}
              onChange={onUsernameChange}
            />
          </Grid>

          <Grid item={true}>
            <TextField
              label="Password"
              type="password"
              value={password}
              onChange={onPasswordChange}
            />
          </Grid>
        </CardContent>
        <CardActions>
          <Button onClick={onLoginClick} size="large" color="primary">
            Login
          </Button>
        </CardActions>
      </Card>

      <Card>
        <CardContent>
          <Typography
            style={{ fontSize: FontSizes.Copy, margin: 12 }}
            gutterBottom={true}
          >
            Jrnal is an application for helping residential care homes
            understand their service users' daily management.
          </Typography>
          <Typography style={{ fontSize: FontSizes.Copy, margin: 12 }}>
            To join the BETA program, please contact{" "}
            <a href="mailto:jrnalinfo@gmail.com">jrnalinfo@gmail.com</a>.
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            href="https://play.google.com/apps/testing/com.jrnal"
            target="_blank"
          >
            Android BETA application
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default Splash;
