import {
  AppBar,
  Avatar,
  Button,
  ButtonGroup,
  CircularProgress,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Slider,
  Switch,
  TextField,
  Toolbar,
  Tooltip,
  Typography
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { IResident, IWorker } from "common";
import LoadingErrorWidget from "../Widgets/LoadingErrorWidget";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import DetailBlob from "../DetailBlob";
import DeleteServiceUser from "../modals/DeleteServiceUser";
import Repo from "../Repo";
import { TokenService } from "../TokenService";
import { Link } from "react-router-dom";

interface IProps extends RouteComponentProps<{ id: string }> {}

export const ResidentScreen: React.FunctionComponent<IProps> = ({
  match: {
    params: { id }
  }
}) => {
  const repo = new Repo(new TokenService());
  const [worker, setWorker] = useState<IWorker>({
    error: "",
    isWorking: false
  });
  const [member, setMember] = useState<IResident>({
    firstname: "",
    lastname: "",
    nickname: "",
    id: "",
    dob: new Date(),
    xx: false,
    active: false,
    avatar: "",
    records: []
  });
  const [memberUpdate, setMemberUpdate] = useState<IResident>({
    firstname: "",
    lastname: "",
    nickname: "",
    id: "",
    dob: new Date(),
    xx: false,
    active: false,
    avatar: "",
    records: []
  });

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);

  useEffect(() => {
    setWorker({ error: "", isWorking: true });

    repo
      .ServiceUserGet(id)
      .then(r => {
        setMember(r);
        setMemberUpdate(r);
        setWorker({ error: "", isWorking: false });
        setIsEditing(false);
      })
      .catch(ex => {
        setWorker({ error: ex.message, isWorking: false });
        setIsEditing(false);
      });
  }, [id]);

  const handleCancel = () => {
    setMemberUpdate({ ...member });
    setIsEditing(false);
  };

  const handleSave = () => {
    setWorker({ error: "", isWorking: true });
    const obj: { [k: string]: any } = {};

    if (memberUpdate.firstname !== member.firstname) {
      obj.firstname = memberUpdate.firstname;
    }

    if (memberUpdate.lastname !== member.lastname) {
      obj.lastname = memberUpdate.lastname;
    }

    doUpdate(obj);
  };

  const doUpdate = (obj: { [k: string]: any }) => {
    if (obj !== {}) {
      repo
        .ServiceUserUpdate(id, obj)
        .then(data => {
          setMember(data);
          setMemberUpdate(data);
          setIsEditing(false);
          setWorker({ error: "", isWorking: false });
        })
        .catch(ex => {
          setIsEditing(false);
          setWorker({ error: ex.message, isWorking: false });
        });
    }
  };

  const handleSwitchActive = () => {
    setWorker({ error: "", isWorking: true });

    const obj: { [k: string]: any } = {};
    obj.active = !memberUpdate.active;

    doUpdate(obj);
  };

  const DetailView: React.FunctionComponent = () => {
    return (
      <div>
        <AppBar position="relative" color="default">
          <Toolbar>
            <Avatar alt={member.firstname} src={member.avatar} />
            <Typography
              variant="h6"
              style={{
                flexGrow: 1,
                marginLeft: 16
              }}
            >
              {member.firstname} {member.lastname}
            </Typography>
            <Switch
              checked={member.active}
              onChange={e => handleSwitchActive()}
            />
            <Link to={`../resident/${id}/careplans`}>
              <IconButton color="inherit">
                <Tooltip title="Careplans">
                  <AssignmentIcon />
                </Tooltip>
              </IconButton>
            </Link>
            <IconButton color="inherit" onClick={e => setIsEditing(true)}>
              <Tooltip title="Edit">
                <EditIcon />
              </Tooltip>
            </IconButton>
            <IconButton onClick={e => setIsDeleteOpen(true)} color="inherit">
              <Tooltip title="Delete service user">
                <DeleteIcon />
              </Tooltip>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Grid
          container={true}
          direction="column"
          spacing={3}
          style={{
            flexGrow: 1,
            padding: 30
          }}
        >
          <Grid item={true} alignContent="stretch">
            <DetailBlob title="Nickname" sub={member.nickname} />
          </Grid>
          <Grid item={true}>
            <DetailBlob
              title="Date of Birth"
              sub={new Date(member.dob).toLocaleDateString("en-GB")}
            />
          </Grid>
        </Grid>

        <DeleteServiceUser
          id={member.id}
          isOpen={isDeleteOpen}
          onClose={() => {
            setIsDeleteOpen(false);
          }}
        />
      </div>
    );
  };

  return (
    <div>
      <LoadingErrorWidget {...worker} />
      {!isEditing ? (
        <DetailView />
      ) : (
        <Grid container={true} direction="column" spacing={3}>
          <Grid item={true}>
            <TextField
              label="Firstname"
              value={memberUpdate.firstname}
              onChange={e =>
                setMemberUpdate({ ...memberUpdate, firstname: e.target.value })
              }
            />
          </Grid>

          <Grid item={true}>
            <TextField
              label="Lastname"
              value={memberUpdate.lastname}
              onChange={e =>
                setMemberUpdate({ ...memberUpdate, lastname: e.target.value })
              }
            />
          </Grid>

          <Grid item={true}>
            <ButtonGroup>
              <Button onClick={() => handleCancel()}>Cancel</Button>
              <Button onClick={() => handleSave()}>Save</Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      )}
    </div>
  );
};
