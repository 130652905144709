import React, { useState } from "react";
import Repo from "../Repo";
import {
  Button,
  TextField,
  IconButton,
  Grid,
  Drawer,
  makeStyles,
  createStyles,
  Theme,
  ButtonGroup,
  Typography
} from "@material-ui/core";
import AddCircle from "@material-ui/icons/AddCircle";
import { TokenService } from "../TokenService";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 40,
      paddingRight: 60,
      width: 400
    }
  })
);

const AddCarer: React.SFC = () => {
  const classes = useStyles();
  const repo = new Repo(new TokenService());

  interface State {
    firstname: string;
    lastname: string;
  }

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [values, setValues] = useState<State>({
    firstname: "",
    lastname: ""
  });

  const handleSubmit = () => {
    repo
      .CarerAdd(values.firstname, values.lastname)
      .then(() => {
        setIsModalOpen(false);
        setValues({
          firstname: "",
          lastname: ""
        });
      })
      .catch(() => {
        setIsModalOpen(false);
        setValues({
          firstname: "",
          lastname: ""
        });
      });
  };

  return (
    <div>
      <IconButton onClick={() => setIsModalOpen(true)}>
        <AddCircle />
      </IconButton>

      <Drawer
        anchor="right"
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <Grid container className={classes.root} direction="column" spacing={4}>
          <Grid item>
            <Typography variant="h4">Add Carer</Typography>
          </Grid>
          <Grid item>
            <TextField
              label="Firstname"
              value={values.firstname}
              fullWidth
              onChange={e =>
                setValues({ ...values, firstname: e.target.value })
              }
            />
          </Grid>
          <Grid item>
            <TextField
              label="Lastname"
              fullWidth
              value={values.lastname}
              onChange={e => setValues({ ...values, lastname: e.target.value })}
            />
          </Grid>
          <Grid item>
            <ButtonGroup size="large" fullWidth>
              <Button
                color="secondary"
                autoFocus
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </Button>
              <Button color="primary" onClick={() => handleSubmit()}>
                Add
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Drawer>
    </div>
  );
};

export default AddCarer;
