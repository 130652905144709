import React from "react";
import { IResident } from "common";
import ImageIcon from "@material-ui/icons/Image";
import { Link } from "react-router-dom";
import {
  ListItemAvatar,
  Avatar,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Badge
} from "@material-ui/core";
import AddServiceUser from "../modals/AddServiceUser";

interface IProps {
  serviceUsers: IResident[];
  onSelected(): any;
}

const ServiceUserListItems: React.FunctionComponent<IProps> = props => {
  return (
    <div>
      <ListItem>
        <ListItemText primary="Service Users" />
        <ListItemSecondaryAction>
          <AddServiceUser />
        </ListItemSecondaryAction>
      </ListItem>

      {props.serviceUsers.map(serviceUser => {
        return (
          <ListItem
            button={true}
            component={Link}
            onClick={() => {
              props.onSelected();
            }}
            to={`/su/${serviceUser.id}`}
            key={`su_${serviceUser.id}`}
          >
            <ListItemAvatar>
              <Badge
                variant="dot"
                color="primary"
                invisible={serviceUser.active}
              >
                <Avatar alt={serviceUser.firstname} src={serviceUser.avatar} />
              </Badge>
            </ListItemAvatar>
            <ListItemText
              primary={serviceUser.firstname + " " + serviceUser.lastname}
              secondary={serviceUser.active}
            />
          </ListItem>
        );
      })}
    </div>
  );
};

export default ServiceUserListItems;
