import {
  Avatar,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  GridList,
  Card,
  CardContent,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  Hidden,
  Paper
} from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import { IRecord, IWorker, IResident, FontSizes, TextColors } from "common";
import React, { useEffect, useState } from "react";
import Repo from "./Repo";
import { TokenService } from "./TokenService";
import { AppContext } from "../App";
import { Link } from "react-router-dom";

const Dashboard: React.FunctionComponent = () => {
  const repo = new Repo(new TokenService());
  
  const [worker, setWorker] = useState<IWorker>({
    error: "",
    isWorking: false
  });

  const [records, setRecords] = useState<IRecord[]>([]);
  const [residents, setResidents] = useState<IResident[]>([]);

  const onLoad = () => {
    setWorker({ error: "", isWorking: true });

    repo
      .ServiceUsers()
      .then(results => {
        setResidents(results);
        repo
          .AllRecords()
          .then(results => {
            setRecords(results);
            setWorker({ error: "", isWorking: false });
          })
          .catch(ex => {
            throw ex;
          });
      })
      .catch(ex => {
        setWorker({ error: ex.message, isWorking: false });
      });
  };

  useEffect(() => {
    onLoad();
  }, []);


  const RecordCard = (res: IRecord) => {
    return (
      <Card
        key={"record_" + res.id}
        style={{ marginRight: 6, marginBottom: 12, width: 340 }}
      >
        <CardContent>
          <Typography style={{ fontSize: FontSizes.Title }} gutterBottom={true}>
            {res.categoryKey}
          </Typography>
          <Typography style={{ fontSize: FontSizes.Small }}>
            {res.categoryTags.map(tag => {
              return tag + ", ";
            })}
          </Typography>

          <Typography
            style={{
              fontSize: FontSizes.Copy,
              marginTop: 12,
              marginBottom: 12
            }}
          >
            {res.detail}
          </Typography>
          <Typography
            style={{
              color: TextColors.Secondary,
              fontSize: FontSizes.Small
            }}
          >
            {res.dateOccurred.when +
              " by " +
              res.carer.firstname +
              " " +
              res.carer.lastname}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  return (
    <AppContext.Consumer>{(configuration)=>{
      return (
    <Grid> 
      {worker.isWorking && <CircularProgress />}

      <Table>
        <TableHead>
          <TableRow>
          <TableCell>
            Service Users
            </TableCell>
            {configuration.configuration.categories.map(h => {
              return (
                <TableCell>
                {h.label}
                </TableCell>            
              )
            })
          }
              </TableRow>
        </TableHead>
        <TableBody>
        {residents.map(resident => {
        return (
          <TableRow key={"resident_" + resident.id}>
            <TableCell>
              {resident.firstname} {resident.lastname}
            </TableCell>
            {configuration.configuration.categories.map(h => {
              return (
                <TableCell   
                  style={{borderLeftColor:records.filter(x => x.resident.id === resident.id && x.categoryKey === h.key).length-1 >= h.minEntries ? "green": "red", borderLeftWidth:4, borderLeftStyle:"solid", margin:4}}>
               
   
                {records
                .filter(x => x.resident.id === resident.id && x.categoryKey === h.key)
                .map(record => <Link to={"/resident/" + resident.id +"/record/" + record.id }>
                      <div style={{ overflow:"Hidden", margin:4}}>
                        {record.categoryTags.map(tag=><div>{tag}</div>)}
                      </div> 
                    </Link>)}          
              </TableCell>            
              )
            })
          }
          </TableRow>
        )})}
        </TableBody>
      </Table>
      
      </Grid>)}}</AppContext.Consumer>
  );
};

export default Dashboard;