import { ITokenStorage, IToken } from 'common';

export class TokenService implements ITokenStorage {
  
    key:string = "id_token";

    set(idToken:string):void {
        localStorage.setItem(this.key, idToken);
    };

    getAsync = async():Promise<IToken> => {
       
        let token:IToken = {
            bearer:""
        }

        var cache = localStorage.getItem(this.key);

        if(cache) {
            token.bearer = cache.toString();
        }

        return Promise.resolve(token);
    }

    remove():void{
        localStorage.removeItem(this.key)
    }
}