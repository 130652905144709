import {
  CircularProgress,
  createStyles,
  Divider,
  Drawer,
  List,
  makeStyles,
  Theme
} from "@material-ui/core";
import { ICarer, IDevice, IResident, IWorker } from "common";
import Pusher from "pusher-js";
import React, { useEffect, useState } from "react";
import CarerListItems from "../ListItems/CarerListItems";
import DeviceListItems from "../ListItems/DeviceListItems";
import ServiceUserListItems from "../ListItems/ServiceUserListItems";
import Repo from "../Repo";
import { TokenService } from "../TokenService";

interface IProps {
  isLoggedIn: boolean;
  carehome: string;
  isOpen: boolean;
  onClose(): any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      margin: theme.spacing(4)
    },
    drawer: {
      width: 400
    }
  })
);

const NavList: React.FunctionComponent<IProps> = props => {
  const [devices, setDevices] = useState<IDevice[]>([]);
  const [carers, setCarers] = useState<ICarer[]>([]);
  const [serviceUsers, setServiceUsers] = useState<IResident[]>([]);
  const [worker, setWorker] = useState<IWorker>({
    isWorking: false,
    error: ""
  });

  const classes = useStyles();

  const repo = new Repo(new TokenService());

  /*
    const fetchOpenShifts = () => {
        broker.fetch("v1/shifts", {
            method: 'GET'
        }).then(r=>{
            if (r !== null) {            
                setShifts(r);
            }
        })
    }
    */

  const fetchServiceUsers = () => {
    setWorker({ isWorking: true, error: "" });

    repo
      .ServiceUsers()
      .then(data => {
        setServiceUsers(data);
        setWorker({ isWorking: false, error: "" });
      })
      .catch(ex => {
        setWorker({ isWorking: false, error: ex.message });
      });
  };

  const fetchDevices = () => {
    setWorker({ isWorking: true, error: "" });

    repo
      .GetDevices()
      .then((data: IDevice[]) => {
        setDevices(data);
        setWorker({ isWorking: false, error: "" });
      })
      .catch(ex => {
        setWorker({ isWorking: false, error: ex.message });
      });
  };

  const fetchCarers = () => {
    setWorker({ isWorking: true, error: "" });

    repo
      .Carers()
      .then(data => {
        setCarers(data);
        setWorker({ isWorking: false, error: "" });
      })
      .catch(ex => {
        setWorker({ isWorking: false, error: ex.message });
      });
  };

  const pusherClient = new Pusher("e0ff81a2bc64c9bf2baf", {
    cluster: "eu",
    forceTLS: true
  });

  useEffect(() => {
    fetchServiceUsers();
    fetchCarers();
    fetchDevices();
    // fetchOpenShifts();
  }, [props.isLoggedIn]);

  useEffect(() => {
    if (props.carehome.length > 0) {
      pusherClient.subscribe(`ch-${props.carehome}`);

      pusherClient.bind("su_new", data => {
        serviceUsers.push(data);
        setServiceUsers([...serviceUsers]);
      });

      pusherClient.bind("su_delete", data => {
        // Needs to be != to enfoce null check
        setServiceUsers([...serviceUsers.filter(su => su.id !== data)]);
      });

      pusherClient.bind("carer_new", data => {
        setCarers([...carers, data]);
      });

      pusherClient.bind("carer_delete", data => {
        // Needs to be != to enfoce null check
        setCarers([...carers.filter(c => c.id !== data)]);
      });
    } else {
      pusherClient.unsubscribe(`ch-${props.carehome}`);
    }
  }, [props.carehome]);

  return (
    <Drawer
      open={props.isOpen}
      anchor="left"
      onClose={() => props.onClose()}
      className={classes.drawer}
    >
      {worker.isWorking ? (
        <CircularProgress className={classes.progress} />
      ) : (
        <List>
          <ServiceUserListItems
            serviceUsers={serviceUsers}
            onSelected={() => {
              props.onClose();
            }}
          />
          <Divider />
          <CarerListItems
            carers={carers}
            onSelected={() => {
              props.onClose();
            }}
          />
          <Divider />
          <DeviceListItems
            devices={devices}
            onSelected={() => props.onClose()}
          />
        </List>
      )}
    </Drawer>
  );
};

export default NavList;
