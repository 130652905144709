import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { IWorker } from "common";
import React from "react";

const LoadingErrorWidget = (w: IWorker) => {
  return (
    <Container style={{ backgroundColor: "red", flex: 1 }}>
      {w.isWorking && <CircularProgress />}
      <Typography>{w.error}</Typography>
    </Container>
  );
};

export default LoadingErrorWidget;
