import { IShift } from "common";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import Repo from "./Repo";
import { TokenService } from "./TokenService";

interface IProps extends RouteComponentProps<{ id: string }> {}

const Shift: React.FunctionComponent<IProps> = ({
  match: {
    params: { id }
  }
}) => {
  const repo = new Repo(new TokenService());

  const [shift, setShift] = useState<IShift>({
    id: "",
    started: new Date(),
    ended: new Date(),
    carer: {
      id: "",
      firstname: "",
      lastname: "",
      active: false,
      avatar: ""
    }
  });

  useEffect(() => {
    repo
      .Shift(id)
      .then((data: IShift) => {
        setShift(data);
      })
      .catch(() => {});
  }, [id]);

  return (
    <div>
      <h1>Shift</h1>
      {shift.carer.firstname}
    </div>
  );
};

export default Shift;
